import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger.jsx';
import Dropdown from './Dropdown.jsx';
import '../../stylesheets/Navbar.css';

const Navbar = () => {
    const [navColor, setNavColor] = useState(false);
    const navbar = useRef();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
    }, []);
    
    const handleScroll = () => {
        if(window.scrollY > navbar.current.offsetHeight){
            setNavColor(true);
        } else {
            setNavColor(false);
        }
    }

    return (
        <nav 
        className={navColor ? 'navbar scrolled' :'navbar'} 
        ref={navbar}
        onScroll={handleScroll}
        >
            <div id='brand'>
                <Link to='/'>
                    <p>
                        The Hawaii Trader
                    </p>
                </Link>
            </div>
            <Hamburger>
                <Dropdown />
            </Hamburger>
            <div className='nav-links'>
                <Link to='/'>Home</Link>
                <Link to='/about'>About</Link>
                <Link to='/contact'>Contact</Link>
            </div>
        </nav>
    )
}

export default Navbar
import React, { useState } from 'react';

const Hamburger = (props) => {
    const [active, setActive] = useState(false);
    const widthMatch = window.matchMedia('(min-width: 804px)');
    widthMatch.addEventListener('change', mw => {
        if(mw.matches) {
            setActive(false);
        }
    });
    const hamburgerClick = () => {
        setActive(!active);
    }
    return (
        <div onClick={hamburgerClick} className={active ? 'menu-btn open' : 'menu-btn'}>
            <div className='hamburger-menu'></div>
            {active && props.children}
        </div>
    )
}

export default Hamburger;
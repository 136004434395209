import React, { useState } from 'react';
import Header from './partials/Header.jsx';
import '../stylesheets/Contact.css';

const Contact = () => {
    const [form, setForm] = useState({
        subject: '',
        body: ''
    });

    const handleSubmit = () => {
        window.open(`mailto:gio@thehawaiitrader.com?subject=${form.subject}&body=${form.body}`);
    }
    
    return (
        <>
            <Header 
            img='https://images.unsplash.com/photo-1484652545326-bf8ec420dc21?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80' 
            title='Contact'
            />
            <main className='contact'>
                <div className='contact-form'>
                    <div className='form-group'>
                        <label htmlFor='subject'>Subject</label>
                        <input
                        className='contact-input'
                        type='text'
                        onChange={e => setForm({ ...form, subject: e.target.value })}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='name'>Message</label>
                        <textarea
                        className='contact-input'
                        cols="30"
                        rows="10"
                        onChange={e => setForm({ ...form, body: e.target.value })}></textarea>
                    </div>
                    <div className="form-group">
                        <button
                        onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Contact
import React from 'react';
import { Link } from 'react-router-dom';

const Dropdown = () => {
    const DropdownItem = (props) => {
        return (
            <Link className='menu-item' to={props.to}>{props.children}</Link>
        )
    }
    return (
        <div className='dropdown'>
            <DropdownItem to='/'>Home</DropdownItem>
            <DropdownItem to='/about'>About</DropdownItem>
            <DropdownItem to='/contact'>Contact</DropdownItem>
        </div>
    )
}

export default Dropdown
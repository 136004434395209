import React from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/ArticleCard.css';

const ArticleCard = ({ article }) => {
    console.log(article)
    return (
        <section className='article-card'>
            <img className='card-image' src={article.image} alt='Article Card'/>
            <div className='card-content'>
                <time>{article.timeCreated.toDate().toString().slice(4, 16)}</time>
                <Link to={`/${article.slug}`}>
                    <h2 className='card-title'>
                        {article.title}
                    </h2>
                </Link>
            </div>
        </section>
    )
}

export default ArticleCard
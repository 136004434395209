import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../configs/FirebaseConfig.js';
import Header from './partials/Header.jsx';
import ArticleCard from './ArticleCard.jsx';
import Loader from './Loader.jsx';
import '../stylesheets/Home.css';

const Home = () => {
    const firestore = firebase.firestore();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const postArr = [];

        firestore.collection('posts').get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                postArr.push(doc.data());
            });

            const byDate = postArr.sort((a, b) => a.timeCreated - b.timeCreated);

            setPosts(byDate);

        });
        // eslint-disable-next-line
    }, []);

    let feature = posts.length === 0 ? null : posts[0];
    let slicedContent = posts.length === 0 ? null : { __html: feature.content.__html.slice(0, 300).trim() + '...' };

    return (
            <div className='home'>
                <Header 
                img='https://images.unsplash.com/photo-1521706862577-47b053587f91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80' 
                logo 
                />
                <main>
                    {posts.length === 0 ? 
                    <div className='home-loader'>
                        <Loader />
                    </div> :
                    <>
                        <article className="main-post">
                            <img src={feature.image} alt=''/>
                            <div>
                                <h2><Link to={`/${feature.slug}`} >{feature.title}</Link></h2>
                                <section dangerouslySetInnerHTML={slicedContent}></section>
                            </div>
                        </article>
                        <section className='article-section'>
                            {posts.slice(1).map(article => (
                                    <ArticleCard key={article.slug} article={article} />
                            ))}
                        </section>
                    </>
                    }
                </main>
            </div>
    )
}

export default Home
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from './components/partials/Navbar';
import Home from './components/Home.jsx';
import Article from './components/Article.jsx';
import About from './components/About.jsx';
import Contact from './components/Contact.jsx';

function App() {
  return (
      <div className="App">
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/:slug' component={Article} />
        </Switch>
      </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import firebase from '../configs/FirebaseConfig.js';
import ArticleCard from './ArticleCard';
import Header from './partials/Header.jsx';
import Loader from './Loader.jsx';
import '../stylesheets/Article.css';

const Article = (() => {
    const firestore = firebase.firestore();
    const { slug } = useParams();

    const [post, setPost] = useState({});
    const [posts, setPosts] = useState([]);

    const getAllData = async () => {
        try {
            const postArr = []
            const snapshot = await firestore.collection('posts').get()
            snapshot.forEach(doc => {
                postArr.push(doc.data())
            });

            const mainPost = postArr.find(post => post.slug === slug)
            setPost(mainPost)
            
            const byDate = postArr.sort((a, b) => (a.timeCreated - b.timeCreated));
            const final = byDate.filter(post => post.slug !== slug);

            setPosts(final);

        } catch (error) {
            console.log(error)
        }
    }

    console.log(posts)

    useEffect(() => {
        getAllData();
        // eslint-disable-next-line
    }, [slug]);
    
    return (
        <div>
            {post === {} ? <Loader /> : 
                <>
                    <Header img={post.image} title={post.title} />
                    <main className='article-main'>
                        <article className='article-text' dangerouslySetInnerHTML={post.content}></article>
                        <section className="other-articles">
                            <h1>Other Articles:</h1>
                            {posts.slice(0, 2).map(post => (
                                <ArticleCard key={post.slug} article={post} />
                            ))}
                        </section>
                    </main>
                </>
                }
        </div>
    )
});

export default Article
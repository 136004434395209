import React from 'react';
import Header from './partials/Header.jsx';

const About = () => {
    return (
        <>
            <Header 
            img='https://images.unsplash.com/photo-1470058869958-2a77ade41c02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'
            title='About'
            />
            <main  style={{ textAlign: 'center', fontSize: '1.2em', maxWidth: '40vw', margin: '0 auto' }}>
                <p>Welcome to my trading blog.  I am a volatility trader trying to make sense (or cents) off the market.  I have been trading for over 15 years, through several market corrections, crashes, and rallies.</p>
                <p>My style of trading leans toward swing trading, but focused more on volatility and weighted positions.  My favorite indicator is the VIX and any indicator that tracks sentiment, emotion, and panic in the market.  I live by the motto, to "Short the greed.  Buy the panic."  </p>
                <p>To give you an idea of my trading style, my favorite books on trading are psychology and human behavior subjects.  To succeed in trading, you have know your "competition", what's going on in their mind, what makes people sell too early, or buy too late.  Throughout my blog, I hope to articulate these market inefficiencies in a way to justify holding a position, long or short.</p>
                <p>Any positions I take in the market are for informational purposes only, and are not to be taken as financial advise. But hopefully you can learn something along with me, and make money along the way!</p>
                <p>And of course, I live in Hawaii.  Yes, the market opens up at 4am, but at least I get to head to the beach when the rest of the island goes to work.</p>
            </main>
        </>
    )
}

export default About
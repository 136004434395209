import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBrZ6avplNuVGqUAslTNvNXG0HAJdHbbx0",
    authDomain: "hawaii-trader-blog.firebaseapp.com",
    databaseURL: "https://hawaii-trader-blog.firebaseio.com",
    projectId: "hawaii-trader-blog",
    storageBucket: "hawaii-trader-blog.appspot.com",
    messagingSenderId: "797708575314",
    appId: "1:797708575314:web:70174bdbe06654836cb58b"
};

firebase.initializeApp(firebaseConfig);

export default firebase
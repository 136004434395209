import React from 'react';
import '../../stylesheets/Header.css';

const Header = ({ img, title, logo }) => {
    return(
        <>
            <header>
                <div className='header-image' style={{backgroundImage: `url(${img})`}}></div>
                <h1 className='header-title'>{title}</h1>
                {logo ? <img src={require('../../assets/Logo.png')} alt='' /> : null }
            </header>
        </>
    )
}

export default Header